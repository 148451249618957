'use client';

import { useRouter } from 'next/navigation';
import { asText, Content } from '@prismicio/client';

type CloseMenuProps = {
  configuration: Content.GlobalConfigurationDocumentData;
};

export const CloseMenu = ({ configuration }: CloseMenuProps) => {
  const router = useRouter();

  return (
    <a
      href="#"
      className="drawer-close"
      onClick={() => router.back()}
      aria-label={asText(configuration.close_menu_label)}
    />
  );
};
